import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from '@/plugins/auth';
import userTypes from '@/plugins/userTypes';
import { onImageupload } from '@/plugins/utils';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

const { body } = document;

export default new Vuex.Store({
  state: {
    isLoaderActive: false,
    showModalELearning: false,
    classRoomVideos: [],
    user: auth.user(),
    loading: false,
    isCollapse: false,
    theme: 'light',
    userTypes,
    heightTable: 'calc(100vh - 340px)',
    showModalChangePassword: false,
    departments: [],
    provinces: [],
    districts: [],
    identityDocumentTypes: [],
    vehicleModels: [],
    vehicleYears: [],
    vehicleColors: [],
    subgroups: [],
    brands: [],
    catchmentOrigins: [],
    structures: [],
    benefits: [],
    typeBells: [],
    typeBellOptions: [],
    bells: [],
    states: [],
    safes: [],
    insurances: [],
    insuranceQuotas: [],
    accesories: [],
    categories: [],
    toyotaValues: [],
    circulars: [],
    windowWidth: window.innerWidth,
    vehicleObservations: [],
    persons: [],
    typeJobs: [],
    tasksProgramming: [],
    vehicleId: null,
    jobs: [],
    vehicles: [],
    reasons: [],
    locals: [],
    locations: [],
    vehicle: [],
    checklist: [],
    matriz: [],
    ocvs: [],
    ocvId: null,
    ocv: null,
    informationVehicle: [],
    ocvEvent: null,
    tinyMceConfig: {
      height: 500,
      inline: false,
      menubar: false,
      fontsize_formats:
        '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px',
      plugins:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code',
      toolbar1:
        'formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | hr | removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | code',
      image_advtab: true,
      file_picker_types: 'file image media',
      image_title: true,
      automatic_uploads: true,
      images_upload_handler: onImageupload,
      images_upload_credentials: true,
      powerpaste_word_import: 'clean',
      powerpaste_html_import: 'clean',
      language_url: '/js/tiny-mce-es.js',
      language: 'es',
      images_file_types: 'jpg,svg,webp,png,gif,jpeg',
      relative_urls: false,
    },
    moneyLaundering: {},
    isEdit: false,
    typeSoatOptions: ['FÍSICO', 'DIGITAL'],
    typeDeliveryOptions: ['FACTURAR', 'REGALO'],
    advVehicleSaleStatus: {
      libre: 1,
      reservado: 2,
      reservadoC: 3,
      reservadoL: 4,
      testDrive: 14,
      bloqueado: 17,
      devolucion: 20,
      anticipo: 5,
      standBy: 18,
    },
    advVehicleLockStatus: ['KINTO', 'EXIBICIÓN', 'ACTIVO', 'BLOQUEADO', 'EXIBICION', 'TEST DRIVE'],
    notifications: [],
    isExecutingMethod: false,
  },
  mutations: {
    SET_LOADER_ACTIVE(state, payload) {
      state.isLoaderActive = payload;
    },
    ON_ADD_NOTIFICATION(state, payload) {
      state.notifications.unshift(payload);
    },
    ON_SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    ON_DELETE_NOTIFICATION(state, payload) {
      state.notifications = state.notifications.filter((item) => item.id !== payload.id);
    },
    ON_MARK_NOTIFICATION_AS_READ(state, notificationId) {
      const notification = state.notifications.find((item) => item.id === notificationId);
      if (notification) {
        notification.is_read = true;
      }
    },
    ON_SET_SHOWMODALELEARNING(state, payload) {
      state.showModalELearning = payload;
    },
    ON_SET_CLASSROOMVIDEOS(state, payload) {
      state.classRoomVideos = payload;
    },
    ON_SET_IDENTITYDOCUMENTTYPES(state, payload) {
      state.identityDocumentTypes = payload;
    },
    ON_SET_INFORMATIONVEHICLE(state, payload) {
      state.informationVehicle = payload;
    },
    ON_SET_STATES(state, payload) {
      state.states = payload;
    },
    ON_SET_REASONS(state, payload) {
      state.reasons = payload;
    },
    ON_SET_OCVS(state, payload) {
      state.ocvs = payload;
    },
    ON_SET_OCV(state, payload) {
      state.ocv = payload;
    },
    ON_SET_OCVEVENT(state, payload) {
      state.ocvEvent = payload;
    },
    ON_SET_CHECKLIST(state, payload) {
      state.checklist = payload;
    },
    ON_SET_LOCALS(state, payload) {
      state.locals = payload;
    },
    ON_SET_LOCATIONS(state, payload) {
      state.locations = payload;
    },
    ON_SET_VEHICLE(state, payload) {
      state.vehicle = payload;
    },
    ON_SET_PERSONS(state, payload) {
      state.persons = payload;
    },
    ON_SET_JOBS(state, payload) {
      state.jobs = payload;
    },
    ON_SET_VEHICLES(state, payload) {
      state.vehicles = payload;
    },
    ON_SET_VEHICLEMODELS(state, payload) {
      state.vehicleModels = payload;
    },
    ON_SET_BRANDS(state, payload) {
      state.brands = payload;
    },
    ON_SET_VEHICLEYEARS(state, payload) {
      state.vehicleYears = payload;
    },
    ON_SET_VEHICLECOLORS(state, payload) {
      state.vehicleColors = payload;
    },
    ON_SET_SUBGROUPS(state, payload) {
      state.subgroups = payload;
    },
    ON_SET_TYPEJOBS(state, payload) {
      state.typeJobs = payload;
    },
    ON_SET_VEHICLEID(state, payload) {
      state.vehicleId = payload;
    },
    ON_SET_OCVID(state, payload) {
      state.ocvId = payload;
    },
    ON_SET_TASKSPROGRAMMING(state, payload) {
      state.tasksProgramming = payload;
    },
    ON_SET_VEHICLEOBSERVATIONS(state, payload) {
      state.vehicleObservations = payload;
    },
    ON_SET_STRUCTURES(state, payload) {
      state.structures = payload;
    },
    ON_SET_BENEFITS(state, payload) {
      state.benefits = payload;
    },
    ON_SET_TYPE_BELL_OPTIONS(state, payload) {
      state.typeBellOptions = payload;
    },
    ON_SET_SAFES(state, payload) {
      state.safes = payload;
    },
    ON_SET_INSURANCES(state, payload) {
      state.insurances = payload;
    },
    ON_SET_INSURANCE_QUOTAS(state, payload) {
      state.insuranceQuotas = payload;
    },
    ON_SET_ACCESORIES(state, payload) {
      state.accesories = payload;
    },
    ON_SET_CIRCULARS(state, payload) {
      state.circulars = payload;
    },
    ON_SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    ON_SET_TYPEBELLS(state, payload) {
      state.typeBells = payload;
    },
    ON_SET_MATRIZ(state, payload) {
      state.matriz = payload;
    },
    ON_SET_BELLS(state, payload) {
      state.bells = payload;
    },
    ON_SET_TOYOTAVALUES(state, payload) {
      state.toyotaValues = payload;
    },
    ON_SET_CATCHMENTORIGINS(state, payload) {
      state.catchmentOrigins = payload;
    },
    ON_SET_DEPARTMENTS(state, payload) {
      state.departments = payload;
    },
    ON_SET_PROVINCES(state, payload) {
      state.provinces = payload;
    },
    ON_SET_DISTRICTS(state, payload) {
      state.districts = payload;
    },
    ON_SHOW_MODAL_CHANGE_PASSWORD(state) {
      state.showModalChangePassword = true;
    },
    ON_HIDE_MODAL_CHANGE_PASSWORD(state) {
      state.showModalChangePassword = false;
    },
    REFRESH_USER_DATA(state) {
      state.user = auth.user();
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    ON_CHANGE_THEME(state) {
      if (state.theme === 'dark') {
        body.classList.remove('theme__dark');
        body.classList.add('theme__light');
        state.theme = 'light';
      } else {
        body.classList.remove('theme__light');
        body.classList.add('theme__dark');
        state.theme = 'dark';
      }
    },
    ON_HIDE_SHOW_SIDEBAR(state) {
      if (state.isCollapse) {
        body.classList.remove('is-collapse');
        state.isCollapse = false;
      } else {
        body.classList.add('is-collapse');
        state.isCollapse = true;
      }
    },
    ON_CLOSE_MENU(state) {
      body.classList.remove('show-menu');
      state.isShowMenu = false;
    },
    ON_OPEN_MENU(state) {
      body.classList.add('show-menu');
      state.isShowMenu = true;
    },
    SET_WINDOW_WIDTH(state, payload) {
      state.windowWidth = payload;
    },
    async ON_FETCH_UBIGEO(state) {
      await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL_BASE}ubigeo`,
      }).then((response) => {
        const { data } = response;
        state.departments = data.data.departments;
        state.provinces = data.data.provinces;
        state.districts = data.data.districts;
      });
    },
    updateField,
    ON_EXECUTE_METHOD(state) {
      state.isExecutingMethod = !state.isExecutingMethod;
    },
  },
  actions: {
    ON_ADD_NOTIFICATION(context, payload) {
      context.commit('ON_ADD_NOTIFICATION', payload);
    },
    ON_SET_NOTIFICATIONS(context, payload) {
      context.commit('ON_SET_NOTIFICATIONS', payload);
    },
    ON_DELETE_NOTIFICATION(context, payload) {
      context.commit('ON_DELETE_NOTIFICATION', payload);
    },
    async ON_MARK_NOTIFICATION_AS_READ(context, notificationId) {
      try {
        const response = await axios.post(`notifications/${notificationId}/mark-as-read`, {
          is_read: true,
        });
        if (response.data.success) {
          context.commit('ON_MARK_NOTIFICATION_AS_READ', notificationId);
        }
      } catch (error) {
        console.error('Error al marcar la notificación como leída:', error);
      }
    },
    ON_SET_SHOWMODALELEARNING(context, payload) {
      context.commit('ON_SET_SHOWMODALELEARNING', payload);
    },
    ON_SET_CLASSROOMVIDEOS(context, payload) {
      context.commit('ON_SET_CLASSROOMVIDEOS', payload);
    },
    ON_SET_STATES(context, payload) {
      context.commit('ON_SET_STATES', payload);
    },
    ON_SET_INFORMATIONVEHICLE(context, payload) {
      context.commit('ON_SET_INFORMATIONVEHICLE', payload);
    },
    ON_SET_PERSONS(context, payload) {
      context.commit('ON_SET_PERSONS', payload);
    },
    ON_SET_OCVS(context, payload) {
      context.commit('ON_SET_OCVS', payload);
    },
    ON_SET_OCV(context, payload) {
      context.commit('ON_SET_OCV', payload);
    },
    ON_SET_OCVEVENT(context, payload) {
      context.commit('ON_SET_OCVEVENT', payload);
    },
    ON_SET_VEHICLE(context, payload) {
      context.commit('ON_SET_VEHICLE', payload);
    },
    ON_SET_CHECKLIST(context, payload) {
      context.commit('ON_SET_CHECKLIST', payload);
    },
    ON_SET_JOBS(context, payload) {
      context.commit('ON_SET_JOBS', payload);
    },
    ON_SET_LOCALS(context, payload) {
      context.commit('ON_SET_LOCALS', payload);
    },
    ON_SET_LOCATIONS(context, payload) {
      context.commit('ON_SET_LOCATIONS', payload);
    },
    ON_SET_REASONS(context, payload) {
      context.commit('ON_SET_REASONS', payload);
    },
    ON_SET_VEHICLES(context, payload) {
      context.commit('ON_SET_VEHICLES', payload);
    },
    ON_SET_DEPARTMENTS(context, payload) {
      context.commit('ON_SET_DEPARTMENTS', payload);
    },
    ON_SET_PROVINCES(context, payload) {
      context.commit('ON_SET_PROVINCES', payload);
    },
    ON_SET_DISTRICTS(context, payload) {
      context.commit('ON_SET_DISTRICTS', payload);
    },
    ON_SET_VEHICLEID(context, payload) {
      context.commit('ON_SET_VEHICLEID', payload);
    },
    ON_SET_OCVID(context, payload) {
      context.commit('ON_SET_OCVID', payload);
    },
    ON_SET_TYPEJOBS(context, payload) {
      context.commit('ON_SET_TYPEJOBS', payload);
    },
    ON_SET_TASKSPROGRAMMING(context, payload) {
      context.commit('ON_SET_TASKSPROGRAMMING', payload);
    },
    ON_SET_IDENTITYDOCUMENTTYPES(context, payload) {
      context.commit('ON_SET_IDENTITYDOCUMENTTYPES', payload);
    },
    ON_SET_VEHICLEMODELS(context, payload) {
      context.commit('ON_SET_VEHICLEMODELS', payload);
    },
    ON_SET_BRANDS(context, payload) {
      context.commit('ON_SET_BRANDS', payload);
    },
    ON_SET_VEHICLEYEARS(context, payload) {
      context.commit('ON_SET_VEHICLEYEARS', payload);
    },
    ON_SET_VEHICLECOLORS(context, payload) {
      context.commit('ON_SET_VEHICLECOLORS', payload);
    },
    ON_SET_SUBGROUPS(context, payload) {
      context.commit('ON_SET_SUBGROUPS', payload);
    },
    ON_SET_VEHICLEOBSERVATIONS(context, payload) {
      context.commit('ON_SET_VEHICLEOBSERVATIONS', payload);
    },
    ON_SET_STRUCTURES(context, payload) {
      context.commit('ON_SET_STRUCTURES', payload);
    },
    ON_SET_BENEFITS(context, payload) {
      context.commit('ON_SET_BENEFITS', payload);
    },
    ON_SET_TYPE_BELL_OPTIONS(context, payload) {
      context.commit('ON_SET_TYPE_BELL_OPTIONS', payload);
    },
    ON_SET_CATEGORIES(context, payload) {
      context.commit('ON_SET_CATEGORIES', payload);
    },
    ON_SET_SAFES(context, payload) {
      context.commit('ON_SET_SAFES', payload);
    },
    ON_SET_INSURANCES(context, payload) {
      context.commit('ON_SET_INSURANCES', payload);
    },
    ON_SET_INSURANCE_QUOTAS(context, payload) {
      context.commit('ON_SET_INSURANCE_QUOTAS', payload);
    },
    ON_SET_CIRCULARS(context, payload) {
      context.commit('ON_SET_CIRCULARS', payload);
    },
    ON_SET_TOYOTAVALUES(context, payload) {
      context.commit('ON_SET_TOYOTAVALUES', payload);
    },
    ON_SET_ACCESORIES(context, payload) {
      context.commit('ON_SET_ACCESORIES', payload);
    },
    ON_SET_TYPEBELLS(context, payload) {
      context.commit('ON_SET_TYPEBELLS', payload);
    },
    ON_SET_BELLS(context, payload) {
      context.commit('ON_SET_BELLS', payload);
    },
    ON_SET_MATRIZ(context, payload) {
      context.commit('ON_SET_MATRIZ', payload);
    },
    ON_SET_CATCHMENTORIGINS(context, payload) {
      context.commit('ON_SET_CATCHMENTORIGINS', payload);
    },
    ON_FETCH_UBIGEO(context) {
      context.commit('ON_FETCH_UBIGEO');
    },
    ON_SHOW_MODAL_CHANGE_PASSWORD(context) {
      context.commit('ON_SHOW_MODAL_CHANGE_PASSWORD');
    },
    ON_HIDE_MODAL_CHANGE_PASSWORD(context) {
      context.commit('ON_HIDE_MODAL_CHANGE_PASSWORD');
    },
    ON_CHANGE_THEME(context) {
      context.commit('ON_CHANGE_THEME');
    },
    ON_HIDE_SHOW_SIDEBAR(context) {
      context.commit('ON_HIDE_SHOW_SIDEBAR');
    },
    ON_CLOSE_MENU(context) {
      context.commit('ON_CLOSE_MENU');
    },
    ON_OPEN_MENU(context) {
      context.commit('ON_OPEN_MENU');
    },
    SET_LOADING(context, payload) {
      context.commit('SET_LOADING', payload);
    },
    REFRESH_USER_DATA(context) {
      context.commit('REFRESH_USER_DATA');
    },
    ON_LOGIN(context, payload) {
      auth.setToken(payload);
      axios.defaults.baseURL = `${process.env.VUE_APP_API_URL_BASE}dashboard/`;
      context.commit('REFRESH_USER_DATA');
    },
  },
  getters: {
    isLoaderActive(state) {
      return state.isLoaderActive;
    },
    notifications(state) {
      return state.notifications;
    },
    advVehicleLockStatus(state) {
      return state.advVehicleLockStatus;
    },
    advVehicleSaleStatus(state) {
      return state.advVehicleSaleStatus;
    },
    typeDeliveryOptions(state) {
      return state.typeDeliveryOptions;
    },
    typeSoatOptions(state) {
      return state.typeSoatOptions;
    },
    showModalELearning(state) {
      return state.showModalELearning;
    },
    classRoomVideos(state) {
      return state.classRoomVideos;
    },
    tinyMceConfig(state) {
      return state.tinyMceConfig;
    },
    states(state) {
      return state.states;
    },
    informationVehicle(state) {
      return state.informationVehicle;
    },
    persons(state) {
      return state.persons;
    },
    ocvs(state) {
      return state.ocvs;
    },
    ocv(state) {
      return state.ocv;
    },
    ocvEvent(state) {
      return state.ocvEvent;
    },
    checklist(state) {
      return state.checklist;
    },
    vehicle(state) {
      return state.vehicle;
    },
    jobs(state) {
      return state.jobs;
    },
    locals(state) {
      return state.locals;
    },
    locations(state) {
      return state.locations;
    },
    reasons(state) {
      return state.reasons;
    },
    vehicles(state) {
      return state.vehicles;
    },
    identityDocumentTypes(state) {
      return state.identityDocumentTypes;
    },
    vehicleModels(state) {
      return state.vehicleModels;
    },
    brands(state) {
      return state.brands;
    },
    vehicleYears(state) {
      return state.vehicleYears;
    },
    vehicleColors(state) {
      return state.vehicleColors;
    },
    subgroups(state) {
      return state.subgroups;
    },
    vehicleObservations(state) {
      return state.vehicleObservations;
    },
    structures(state) {
      return state.structures;
    },
    vehicleId(state) {
      return state.vehicleId;
    },
    ocvId(state) {
      return state.ocvId;
    },
    typeJobs(state) {
      return state.typeJobs;
    },
    tasksProgramming(state) {
      return state.tasksProgramming;
    },
    benefits(state) {
      return state.benefits;
    },
    typeBellOptions(state) {
      return state.typeBellOptions;
    },
    categories(state) {
      return state.categories;
    },
    circulars(state) {
      return state.circulars;
    },
    safes(state) {
      return state.safes;
    },
    insurances(state) {
      return state.insurances;
    },
    insuranceQuotas(state) {
      return state.insuranceQuotas;
    },
    toyotaValues(state) {
      return state.toyotaValues;
    },
    accesories(state) {
      return state.accesories;
    },
    typeBells(state) {
      return state.typeBells;
    },
    bells(state) {
      return state.bells;
    },
    matriz(state) {
      return state.matriz;
    },
    catchmentOrigins(state) {
      return state.catchmentOrigins;
    },
    departments(state) {
      return state.departments;
    },
    provinces(state) {
      return state.provinces;
    },
    districts(state) {
      return state.districts;
    },
    showModalChangePassword(state) {
      return state.showModalChangePassword;
    },
    heightTable(state) {
      return state.heightTable;
    },
    userTypes(state) {
      return state.userTypes;
    },
    theme(state) {
      return state.theme;
    },
    isCollapse(state) {
      return state.isCollapse;
    },
    loading(state) {
      return state.loading;
    },
    user(state) {
      return state.user;
    },
    postStates(state) {
      return state.postStates;
    },
    isExecutingMethod(state) {
      return state.isExecutingMethod;
    },
    currentBreakPoint(state) {
      const { windowWidth } = state;
      if (windowWidth >= 1920) return 'xl';
      if (windowWidth >= 1200) return 'lg';
      if (windowWidth >= 992) return 'md';
      if (windowWidth >= 768) return 'sm';
      return 'xs';
    },
    getField,
  },
});
